import { endOfYesterday } from 'date-fns';
import { makeAutoObservable, runInAction } from 'mobx';
import { IntlShape } from 'react-intl';
import { IFishBasinComment } from 'models/comments';
import { IFishBasin } from 'models/fishbasin';
import { DeliveryStatus } from 'models/orders';
import {
  ICompanyListWidgetRow,
  IFeedStockItem,
  ISiteListData,
} from 'models/widget';
import {
  getSiteListWidgetData,
  getIotDeviceInfo,
  getAllActiveComments,
  getSimplifiedBasinList,
  postFishBasinComment,
  getOrders,
  getCompanyById,
  getFeedStockEvents,
  getProducts,
  getIotDeviceActiveAlarms,
} from 'services/api';
import { dateAndTime, formatDate } from 'translations/locales';
import facilityStore from './facility-store';
import siteStore from './site-store';
import toastStore from './toast-store';
import userStore from './user-store';
import filterStore from './filter-store';

/**
 *
 * Common mobx store for all widgets on the dashboard
 *
 */

class DashboardStore {
  constructor() {
    makeAutoObservable(this);
  }

  basinList: IFishBasin[] = [];
  basinListLoading = true;
  siteListWidgetLoading = true;
  siteListWidgetData: ISiteListData[] = [];
  comments: IFishBasinComment[] = [];
  feedStockItems: IFeedStockItem[] = [];
  feedStockItemsLoading = false;
  basinNames: {
    name: string;
    id: number;
    facilityId: number;
    companyId: number | undefined;
    siteId: number | undefined;
  }[] = [];
  productFeedTypeIds: {
    productCode: string;
    feedtypeId: number | null;
  }[] = []

  reset = () => {
    runInAction(() => {
      this.basinList = [];
      this.siteListWidgetData = [];
      this.comments = [];
      this.basinNames = [];
      this.feedStockItems = [];
    });
  };

  get isLoading() {
    return siteStore.isLoading;
  }

  get filteredComments() {
    // filter comments based on company id
    if (filterStore.dataFilter.type === 'company') {
      return this.comments.filter(
        (comment) =>
          comment.fishbasin.site.facility.company?.id ===
          filterStore.dataFilter.id
      );
    }
    // comments based on facility id
    if (filterStore.dataFilter.type === 'facility') {
      return this.comments.filter(
        (comment) =>
          comment.fishbasin.site.facility.id === filterStore.dataFilter.id
      );
    }
    // filter basin list based on site id
    if (filterStore.dataFilter.type === 'site') {
      return this.comments.filter(
        (comment) =>
          comment.fishbasin.site.id === filterStore.dataFilter.id
      );
    }
    // no filter defined, return all
    return this.comments;
  }

  get filteredBasinNames() {
    // filter basin list based on facility id
    if (filterStore.dataFilter.type === 'facility') {
      return this.basinNames.filter(
        (basin) => basin.facilityId === filterStore.dataFilter.id
      );
    }
    // filter basin list based on company id
    if (filterStore.dataFilter.type === 'company') {
      return this.basinNames.filter(
        (basin) => basin.companyId === filterStore.dataFilter.id
      );
    }
    // filter basin list based on site id
    if (filterStore.dataFilter.type === 'site') {
      return this.basinNames.filter(
        (basin) => basin.siteId === filterStore.dataFilter.id
      );
    }
    return this.basinNames;
  }

  /** Computed property for total basin count.
   * Basin count is calculated based on selected id (filterStore.dataFilter) */

  get totalBasinCount() {
    if (filterStore.dataFilter.type === 'facility') {
      return this.basinList.filter(
        (basin) => basin.site.facility.id === filterStore.dataFilter.id
      ).length;
    }
    if (filterStore.dataFilter.type === 'company') {
      return this.basinList.filter(
        (basin) => basin.site.facility.company?.id === filterStore.dataFilter.id
      ).length;
    }
    if (filterStore.dataFilter.type === 'site') {
      return this.basinList.filter(
        (basin) => basin.site.id === filterStore.dataFilter.id
      ).length;
    }
    return this.basinList.length;
  }

  get filteredBasins() {
    let basins = this.basinList;
    if (filterStore.dataFilter.type === 'facility') {
      basins = basins.filter(
        (basin) => basin.site.facility.id === filterStore.dataFilter.id
      );
    }
    if (filterStore.dataFilter.type === 'company') {
      basins = basins.filter(
        (basin) => basin.site.facility.company?.id === filterStore.dataFilter.id
      );
    }
    if (filterStore.dataFilter.type === 'site') {
      basins = basins.filter(
        (basin) => basin.site.id === filterStore.dataFilter.id
      );
    }
    return basins;
  }

  /** Computed property for total fish count.
   * Fish count is calculated based on selected id (filterStore.dataFilter) */

  get totalFishCount() {
    return this.filteredBasins.reduce(
      (total, basin) => total + (basin.currentamount || 0),
      0
    );
  }

  get totalBiomass() {
    return this.filteredBasins.reduce(
      (total, basin) => total + (Number(basin?.currentbiomass_user) || 0),
      0
    );
  }

  get totalCumulativeFeedAmount() {
    return this.filteredBasins.reduce(
      (total, basin) => total + (basin?.laststatus?.cumulativefeed_user || 0),
      0
    );
  }

  get filteredSiteListData() {
    if (filterStore.dataFilter.type === 'facility') {
      return this.siteListWidgetData.filter(
        (s) => s.facilityId === filterStore.dataFilter.id
      );
    }
    if (filterStore.dataFilter.type === 'site') {
      return this.siteListWidgetData.filter(
        (s) => s.id === filterStore.dataFilter.id
      );
    }
    return this.siteListWidgetData;
  }

  /**
   *
   * Flatten site data (from FishBasins) so that in can easily be displayed in company widget
   *
   */
  private flattenSiteData = (row: ICompanyListWidgetRow, basin: IFishBasin) => {
    if (!basin.site.deleted) {
      if (!row.sites) {
        row.sites = new Set();
        row.siteTemperaturesCurrentDay = [];
        row.siteTemperaturesPrevDay = [];
        row.siteTemperaturesLastMeasurements = [];
        row.siteOxygenValuesCurrentDay = [];
        row.siteOxygenValuesPrevDay = [];
        row.siteOxygenLastMeasurements = [];
        row.basinBiomassValues = [];
        row.basinFeedUsageAmounts = [];
      }

      const siteId = basin.site.id;
      if (!row.sites.has(siteId)) {
        row.sites.add(siteId);

        if (
          basin.site.today_temperature?.temperature !== undefined &&
          basin.site.today_temperature?.temperature !== null
        ) {
          row.siteTemperaturesCurrentDay.push(
            basin.site.today_temperature.temperature
          );
        }

        if (
          basin.site.yesterday_temperature?.temperature !== undefined &&
          basin.site.yesterday_temperature?.temperature !== null
        ) {
          row.siteTemperaturesPrevDay.push(
            basin.site.yesterday_temperature.temperature
          );
        }

        if (
          basin.site.last_temperature?.temperature !== undefined &&
          basin.site.last_temperature?.temperature !== null
        ) {
          row.siteTemperaturesLastMeasurements.push(
            basin.site.last_temperature.temperature
          );
        }

        if (
          basin.site.today_oxygen_value?.oxygenvalue !== undefined &&
          basin.site.today_oxygen_value?.oxygenvalue !== null
        ) {
          row.siteOxygenValuesCurrentDay.push(
            basin.site.today_oxygen_value?.oxygenvalue
          );
        }

        if (
          basin.site.yesterday_oxygen_value?.oxygenvalue !== undefined &&
          basin.site.yesterday_oxygen_value?.oxygenvalue !== null
        ) {
          row.siteOxygenValuesPrevDay.push(
            basin.site.yesterday_oxygen_value?.oxygenvalue
          );
        }

        if (
          basin.site.last_oxygen_value?.oxygenvalue !== undefined &&
          basin.site.last_oxygen_value?.oxygenvalue !== null
        ) {
          row.siteOxygenLastMeasurements.push(
            basin.site.last_oxygen_value.oxygenvalue
          );
        }
      }
    }
  };

  /**
   *
   * Flatten FishBasin data so that in can easily be displayed in company widget
   *
   */
  private flattenBasinData = (
    row: ICompanyListWidgetRow,
    basin: IFishBasin
  ) => {
    if (!basin.deleted) {
      if (!row.fishbasins) row.fishbasins = new Set();
      const basinId = basin.id;
      if (!row.fishbasins.has(basinId)) {
        row.fishbasins.add(basinId);
        if (basin.currentbiomass_user !== undefined) {
          row.basinBiomassValues.push(Number(basin.currentbiomass_user) || 0);
        }
        if (basin.currentfeedinfo?.feed_amount !== undefined) {
          row.basinFeedUsageAmounts.push(
            basin.currentfeedinfo?.feed_amount || 0
          );
        }
      }
    }
  };

  private addAveragesAndTooltipsToComapnyWidgetData = (
    intl: IntlShape,
    result: ICompanyListWidgetRow[]
  ) => {
    return result.map((row) => {
      const { facilities } = facilityStore;
      row.facilities = new Set();
      facilities.forEach((f) => {
        if (!f.deleted && f.company?.id === row.companyId) {
          row.facilities.add(f.id);
          // sites are re-added here to correctly count sites that di not have basins yet
          f.sites?.forEach((s) => {
            if (!s.deleted) row.sites.add(s.id);
          });
        }
      });

      let temperatures: number[] = [];
      if (row.siteTemperaturesCurrentDay.length) {
        temperatures = row.siteTemperaturesCurrentDay || [];
      } else {
        temperatures = row.siteTemperaturesLastMeasurements || [];
      }
      row.averageTemperature = temperatures?.length
        ? temperatures.reduce((acc, current) => acc + current || 0, 0) /
          temperatures.length
        : '-';
      // calculate temperature daily delta based on averages and create a tooltips
      if (
        row.siteTemperaturesCurrentDay.length &&
        row.siteTemperaturesPrevDay.length
      ) {
        const averageCurrentDay =
          row.siteTemperaturesCurrentDay.reduce(
            (acc, current) => acc + current || 0,
            0
          ) / row.siteTemperaturesCurrentDay.length || 0;
        const averagePrevDay =
          row.siteTemperaturesPrevDay.reduce(
            (acc, current) => acc + current || 0,
            0
          ) / row.siteTemperaturesPrevDay.length || 0;
        row.temperatureDelta = averageCurrentDay - averagePrevDay;
        row.temperatureTooltipLine1 = `${formatDate(
          intl,
          new Date(),
        )}: ${averageCurrentDay} °C`;
        row.temperatureTooltipLine2 = `${formatDate(
          intl,
          endOfYesterday(),
        )}: ${averagePrevDay} °C`;
      }

      // calculate oxygen daily delta based on averages and create a tooltips
      let oxygenValues: number[] = [];
      if (row.siteOxygenValuesCurrentDay.length) {
        oxygenValues = row.siteOxygenValuesCurrentDay || [];
      } else {
        oxygenValues = row.siteOxygenLastMeasurements || [];
      }
      row.averageOxygen = oxygenValues?.length
        ? oxygenValues.reduce((acc, current) => acc + current || 0, 0) /
          oxygenValues.length
        : '-';

      if (
        row.siteOxygenValuesCurrentDay.length &&
        row.siteOxygenValuesPrevDay.length
      ) {
        const averageCurrentDay =
          row.siteOxygenValuesCurrentDay.reduce(
            (acc, current) => acc + current || 0,
            0
          ) / row.siteOxygenValuesCurrentDay.length || 0;
        const averagePrevDay =
          row.siteOxygenValuesPrevDay.reduce(
            (acc, current) => acc + current || 0,
            0
          ) / row.siteOxygenValuesPrevDay.length || 0;
        row.oxygenDelta = averageCurrentDay - averagePrevDay;
        row.oxygenTooltipLine1 = `${formatDate(
          intl,
          new Date(),
        )}: ${averageCurrentDay} ${intl.formatMessage({
          id: "UnitMgPerLiter"
        })}`;
        row.oxygenTooltipLine2 = `${formatDate(
          intl,
          endOfYesterday(),
        )}: ${averagePrevDay} ${intl.formatMessage({
          id: "UnitMgPerLiter"
        })}`;
      }

      row.biomassTotal =
        row.basinBiomassValues.reduce(
          (acc, current) => acc + current || 0,
          0
        ) || 0;
      row.feeUsageTotal =
        row.basinFeedUsageAmounts.reduce(
          (acc, current) => acc + current || 0,
          0
        ) || 0;

      return row;
    });
  };

  getCompanyWidgetData = (intl: IntlShape) => {
    const data: { [id: string]: ICompanyListWidgetRow } = {};
    const basins =
      filterStore.dataFilter.type !== 'all' && filterStore.dataFilter.id !== -1
        ? this.basinList.filter(
            (f) => f.site.facility.company?.id === filterStore.dataFilter.id
          )
        : this.basinList;
    basins.forEach((basin) => {
      const company = basin.site.facility.company;
      if (company) {
        const row: ICompanyListWidgetRow = data[company.id] || {};
        // set company id and name to row data
        row.companyId = company.id;
        row.companyName = company.name;
        row.activeAlarms = !!(row.activeAlarms || basin.activeAlarms);
        row.activeAlarmsCount = row.activeAlarmsCount || basin.activeAlarmsCount ? (row.activeAlarmsCount != null ? row.activeAlarmsCount : 0) + (basin.activeAlarmsCount != null ? basin.activeAlarmsCount : 0) : 0;

        // flatten site related data (oxygen values, temperature values)
        this.flattenSiteData(row, basin);
        // flatten data from basins (biomass, feed usage)
        this.flattenBasinData(row, basin);
        data[company.id] = row;
      }
    });
    const result = Object.values(data);
    return this.addAveragesAndTooltipsToComapnyWidgetData(intl, result);
  }

  loadSitesListsWidget = async (intl: IntlShape) => {
    try {
      runInAction(() => {
        this.siteListWidgetLoading = true;
      });
      const { data: sites } = await getSiteListWidgetData();
      const mainunitIds = sites
        .map(item => item.mainunit_id)
        .filter((id): id is string => id !== undefined);
      const { data: activeAlarms } = await getIotDeviceActiveAlarms(mainunitIds);

      const data = sites.map((site) => {
        // uses last temperature if a temperature from the current day is not available
        const temperature = site.today_temperature?.temperature
          ? site.today_temperature
          : site.last_temperature;

        // calculate temperature daily delta and create a tooltips
        let temperatureDelta = 0;
        let temperatureTooltipLine1,
          temperatureTooltipLine2 = '';
        if (
          site.today_temperature?.temperature &&
          site.yesterday_temperature?.temperature
        ) {
          temperatureDelta =
            site.today_temperature?.temperature -
            site.yesterday_temperature.temperature;
          temperatureTooltipLine1 = `${dateAndTime(
            intl,
            new Date(site.today_temperature.timestamp),
          )}: ${site.today_temperature.temperature} °C`;
          temperatureTooltipLine2 = `${dateAndTime(
            intl,
            new Date(site.yesterday_temperature.timestamp),
          )}: ${site.yesterday_temperature.temperature} °C`;
        }

        // calculate oxygen daily delta and create a tooltips
        let oxygenDelta = 0;
        let oxygenTooltipLine1,
          oxygenTooltipLine2 = '';
        if (
          site.today_oxygen_value?.oxygenvalue &&
          site.yesterday_oxygen_value?.oxygenvalue
        ) {
          oxygenDelta =
            site.today_oxygen_value?.oxygenvalue -
            site.yesterday_oxygen_value.oxygenvalue;
          oxygenTooltipLine1 = `${dateAndTime(
            intl,
            new Date(site.today_oxygen_value.timestamp),
          )}: ${site.today_oxygen_value.oxygenvalue} ${intl.formatMessage({
            id: "UnitMgPerLiter"
          })}`;
          oxygenTooltipLine2 = `${dateAndTime(
            intl,
            new Date(site.yesterday_oxygen_value.timestamp),
          )}: ${site.yesterday_oxygen_value.oxygenvalue} ${intl.formatMessage({
            id: "UnitMgPerLiter"
          })}`;
        }

        // Calculate total amount from fishbasins
        let feedAmountTotal = 0;
        if (site?.fishbasins) {
          feedAmountTotal =
            site?.fishbasins.reduce((acc, basin) => {
              if (
                basin &&
                !basin.deleted &&
                basin.currentfeedinfo &&
                basin.currentfeedinfo.feed_amount
              ) {
                return acc + basin.currentfeedinfo.feed_amount || 0;
              }
              return acc;
            }, 0) || 0;
        }

        const activeAlarmsCount = activeAlarms.filter((aa: any) => {
          return aa.source === site.mainunit_id;
        }).length || 0;

        return {
          id: site.id,
          temperature,
          temperatureDelta,
          temperatureTooltipLine1,
          temperatureTooltipLine2,
          oxygenDelta,
          oxygenTooltipLine1,
          oxygenTooltipLine2,
          name: site.name,
          oxygen: site.last_oxygen_value?.oxygenvalue,
          biomass: site.total_biomass,
          feedAmountTotal,
          facilityId: site?.facility_id,
          deviceId: site?.mainunit_id,
          isIotDeviceConnected: 'NO DEVICE',
          activeAlarms: activeAlarmsCount > 0,
        } as ISiteListData;
      });
      runInAction(() => {
        this.siteListWidgetLoading = false;
        this.siteListWidgetData = data;
        this.updateIotDeviceAvailability();
      });
    } catch (error) {
      runInAction(() => {
        this.siteListWidgetLoading = false;
        this.siteListWidgetData = [];
      });
    }
  };

  updateIotDeviceAvailability = async () => {
    const updatedData = await Promise.all(
      this.siteListWidgetData.map(async (site) => {
        site.isIotDeviceConnected = 'NO DEVICE';
        if (site.deviceId) {
          site.isIotDeviceConnected = 'UNAVAILABLE';
          try {
            const { data } = await getIotDeviceInfo(site.deviceId);
            if (data.availability === 'AVAILABLE')
              site.isIotDeviceConnected = 'AVAILABLE';
          } catch (error) {}
        }
        return site;
      })
    );
    runInAction(() => {
      this.siteListWidgetData = [...updatedData];
    });
  };

  /**
   * Loads a list all basins user has access to
   */
  loadSimplifiedBasinList = async () => {
    try {
      runInAction(() => {
        this.basinList = [];
        this.basinListLoading = true;
      });

      const { data: basins } = await getSimplifiedBasinList();

      // Extract supportunit_ids and fetch active alarms
      const supportunitIds = basins
        .map(item => item.supportunit_id)
        .filter((id): id is string => id !== undefined);

      const {data: activeAlarms} = await getIotDeviceActiveAlarms(supportunitIds);

      // Count the number of active alarms per devicename
      const alarmCounts = activeAlarms.reduce((acc: { [key: string]: number }, alarm: any) => {
        const { source } = alarm;
        if (source) {
          acc[source] = (acc[source] || 0) + 1;
        }
        return acc;
      }, {});


      runInAction(() => {
        this.basinList = basins as IFishBasin[];

        this.basinList = this.basinList.map(basin => {
          const supportunitId = basin.supportunit_id;
          const count = supportunitId ? alarmCounts[supportunitId] || 0 : 0;
          return {
            ...basin,
            activeAlarms: count > 0,
            activeAlarmsCount: count,
          };
        });

        this.basinNames = this.basinList.map((b: IFishBasin) => ({
          id: b.id as number,
          name: b.name as string,
          facilityId: b?.site?.facility?.id,
          companyId: b?.site?.facility?.company?.id,
          siteId: b?.site?.id,
        }));
        this.basinListLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.basinListLoading = false;
        this.basinList = [];
      });
    }
  };


  loadAllComments = async () => {
    try {
      const { data: comments } = await getAllActiveComments();
      runInAction(() => {
        this.comments = comments;
      });
    } catch (error) {
      runInAction(() => {
        this.comments = [];
      });
    }
  };

  addNewComment = async (basinId: number, comment: string) => {
    try {
      const { data } = await postFishBasinComment(basinId, comment);
      runInAction(() => {
        this.comments.unshift(data);
      });
    } catch (error) {
      toastStore.setToast('CommentPostFailed');
      return false;
    }
  };

  getBasinByProductCode = (productCode: string) => {
    let basinsWithStockedProduct = this.basinList.filter(
      (basin) =>
        basin.lastfeedtype &&
        !basin.deleted &&
        !basin.site.deleted &&
        !basin.site.facility.deleted &&
        basin.lastfeedtype === (this.productFeedTypeIds.find(f => f.productCode === productCode)?.feedtypeId || productCode) &&
        !basin.deleted
    );

    if (
      filterStore.dataFilter.type === 'company' &&
      filterStore.dataFilter.id !== -1
    ) {
      basinsWithStockedProduct = basinsWithStockedProduct.filter(
        (b) => b.site.facility.company?.id === filterStore.dataFilter.id
      );
    }

    if (
      filterStore.dataFilter.type === 'facility' &&
      filterStore.dataFilter.id !== -1
    ) {
      basinsWithStockedProduct = basinsWithStockedProduct.filter(
        (b) => b.site.facility.id === filterStore.dataFilter.id
      );
    }
    return basinsWithStockedProduct;
  };

  loadFeedStockData = async () => {
    const data: { [id: string]: IFeedStockItem } = {};
    try {
      runInAction(() => {
        this.feedStockItems = [];
        this.feedStockItemsLoading = true;
      });

      // 1 load orders from the microservice
      let { data: orders } = await getOrders();
      // 2 filter orders by company and filter out undelivered orders
      const { isRaisioAdminOrSuperuser } = userStore;
      if (isRaisioAdminOrSuperuser) {
        if (
          filterStore.dataFilter.type === 'company' &&
          filterStore.dataFilter.id !== -1
        ) {
          const { data: company } = await getCompanyById(filterStore.dataFilter.id);
          orders = orders.filter(
            (order) =>
              order.companyId &&
              order.companyId === company.id
          );
        }
      }
      orders = orders.filter(
        (order) => order.deliveryInfo.status === DeliveryStatus.DELIVERED
      );

      if (orders.length === 0) {
        // no orders with selected filters, we are done here
        runInAction(() => {
          this.feedStockItems = [];
          this.feedStockItemsLoading = false;
        });
        return;
      }

      // 3 generate list of product ids based on orders
      const productIds: string[] = [];
      orders.forEach((order) => {
        order.customerOrderPackages.forEach((p) => {
          if (p.package?.productCode) productIds.push(p.package.productCode);
        });
      });

      // 4 load feedStockEvents for selected orders and filter by facility id if needed
      let queryString = `?product_ids=${productIds.join(',')}`;
      /* if (
        filterStore.dataFilter.type === 'facility' &&
        filterStore.dataFilter.id !== -1
      ) {
        queryString += `&facility_id=${filterStore.dataFilter.id}`;
      } */

      const { data: feedStockEvents } = await getFeedStockEvents(queryString);

      // 5 load products from the order microservice and generate a productNames lookup object
      const { data: products } = await getProducts();
      try {
        this.productFeedTypeIds = []
        products.forEach((p) => {
          p.packages.forEach((pack) => {
            this.productFeedTypeIds.push({
              productCode: pack.weight === 500 ? (pack.productCode + 0).toString() : pack.productCode,
              feedtypeId: pack.feedtypeId || null
            })
          })
        })
      } catch (error) { console.warn('Error mapping product information', error); }
      const productNames: { [productCode: string]: string } = {};
      products.forEach((product) => {
        product.packages.forEach((prodPackage) => {
          if (prodPackage.weight === 500)
            prodPackage.productCode += 0;
          productNames[
            prodPackage.productCode
          ] = `${product.description} ${prodPackage.description}`;
        });
      });

      // 6 generate feedstock items per ordered product type
      orders.forEach((order) => {
        order.customerOrderPackages.forEach((customerPackage) => {
          if (customerPackage?.package) {
            const productCode = customerPackage.package?.productCode;
            if (productCode) {
              const orderDeliveryDate = order.updated;
              let feedStockItem = data[productCode] || {};
              let initialDeliveryDate =
                feedStockItem.initialDeliveryDate || orderDeliveryDate;
              if (
                initialDeliveryDate &&
                orderDeliveryDate &&
                new Date(initialDeliveryDate) > new Date(orderDeliveryDate)
              ) {
                initialDeliveryDate = orderDeliveryDate;
              }

              data[productCode] = {
                productCode,
                cumulativeAmountDelivered:
                  (feedStockItem.cumulativeAmountDelivered || 0) +
                  ((customerPackage.amountDelivered || 0) *
                    (customerPackage.package?.weight || 0) || 0),
                initialDeliveryDate,
              };
            }
          }
        });
      });

      // 7 add product name and calculate cumulativeStockAmount from feedTypes and from feedStockEvents
      const feedStockItems = Object.values(data);
      feedStockItems.forEach((feedStockItem) => {
        const events = feedStockEvents.filter((e) => {
          return (
            e.feedtype.productid && e.feedtype.productid.toString() === feedStockItem.productCode &&
            new Date(feedStockItem.initialDeliveryDate || 0) <
              new Date(e.timestamp)
          );
        });
        const cumulativeStockAmount = events.reduce((acc, value) => {
          return acc + value.change;
        }, 0);
        feedStockItem.cumulativeStockAmount = cumulativeStockAmount;

        feedStockItem.productName =
          productNames[feedStockItem.productCode] || feedStockItem.productCode;
      });

      runInAction(() => {
        this.feedStockItems = [...feedStockItems];
        this.feedStockItemsLoading = false;
      });
    } catch (error) {
      console.log('Error in feedstock widget data load', error);
      runInAction(() => {
        this.feedStockItems = [];
        this.feedStockItemsLoading = false;
      });
    }
  };
}

export default new DashboardStore();
