/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { debounce, Grid, Typography, useTheme } from '@material-ui/core';
import {IFishBasin, INewCumulativeAmount} from 'models/fishbasin';
import {FC, useCallback, useEffect, useState} from 'react';
import { useIntl } from 'react-intl';
import { useStore } from 'stores/store-hooks';
import { EditableTextField } from './editable-text-field';
import { FishAmountPopover } from './fish-amount-popover';
import { CumulativeFeedAmountPopOver } from './cumulative-feed-amount-popover';

interface IBasinDetail {
  basin: IFishBasin;
}

export const FishSiloDetails: FC<IBasinDetail> = ({ basin}) => {
  const intl = useIntl();
  const theme = useTheme();
  const basinStore = useStore('basinStore');
  const siloStore = useStore('siloStore');
  const { thousandSeparator } = useStore('userStore');

  const totalFeed = ((basin.laststatus?.cumulativefeed_user || 0) +
    (basin.currentfeedinfo?.feed_amount || 0) + (basin.extra_feed_weight || 0)) * 0.001;
  const totalAutoFeed = ((basin.laststatus?.cumulativefeed_user || 0) + (basin.extra_feed_weight || 0)) * 0.001;

  const [siloFeedEstimate, setSiloFeedEstimate] = useState(0);

  // daily feed amount growth multiplier
  // const r = 1 + (basin.usefixedfeedingpercent ? basin.fixedfeedingpercent || NaN : basin.currentfeedinfo?.feed_percentage || NaN) / 100;
  // today's feed amount
  const d = (basin.currentfeedinfo?.feed_amount || NaN) * 0.001;
  // siloFeedEstimate
  // const s = siloFeedEstimate;

  // Updating amount fed today will take some time, so check it, until it is not a null value
  useEffect(() => {
    let count = 0;
    const checkValue = () => {
      if (basinStore.amountFedToday !== null) {
        setSiloFeedEstimate(basinStore.autoFeedOn
          ? basinStore.amountFedToday != null ? siloStore.totalFeederAmount - totalAutoFeed - ((basinStore.amountFedToday || 0) * 0.001) : siloStore.totalFeederAmount - totalFeed
          : siloStore.totalFeederAmount - totalFeed)
        clearInterval(interval);
      }
      count++;
      if (count > 30)
        clearInterval(interval);
    };
    const interval = setInterval(checkValue, 1000);
    return () => clearInterval(interval);
  }, [siloFeedEstimate, basinStore, siloStore.totalFeederAmount, totalFeed, totalAutoFeed])

  // Old logic
  // const provisionDays = Math.log(1 - (s / d) * (1 - r)) / Math.log(r);
  // Simpler logic
  const provisionDays = siloFeedEstimate != null && siloFeedEstimate > 0 && d != null ? siloFeedEstimate / d : 0;

  const updateBasin = async (data: object | INewCumulativeAmount) => {
    const response = await basinStore.updateBasin(basin.id, data as IFishBasin);
    if (response?.data) {
      await basinStore.setSelectedBasin(response.data);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(debounce(updateBasin, 350), []);

  const updateBasinData = (key: string, value: string) => {
    debouncedUpdate({ [key]: value });
  };

  const updateFishAmount = (data: object) => {
    const newCumulative = data as any
    if (newCumulative.new_cumulative_amount){
      newCumulative.autofeed = basinStore.autoFeedOn
      updateBasin(newCumulative as INewCumulativeAmount);
    }else {
      updateBasin(data);
    }

  };

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      css={(theme) => css`
        overflow-y: auto;
        padding: ${theme.spacing(2)}px;
        min-height: 160px;
        align-items: center;
      `}
    >
      <Grid item xs={12}>
        <Grid container spacing={0} direction="row">
          <Grid item xs={6}>
            <Typography component="span" variant="caption" color="textPrimary">
              {`${intl.formatMessage({ id: 'Species' })} `}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="span" variant="h5" color="textPrimary">
              {basin.fishtype
                ? intl.formatMessage({
                    id:
                      basin.fishtype.name.charAt(0).toUpperCase() +
                      basin.fishtype.name.slice(1),
                  }) + (basin.brand ? ' / ' + basin.brand : '')
                : '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0} direction="row">
          <Grid item xs={6}>
            <Typography component="span" variant="caption" color="textPrimary">
              {`${intl.formatMessage({ id: 'AverageWeight' })} `}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <EditableTextField
              component="span"
              variant="h5"
              suffix={intl.formatMessage({ id: "UnitGrams" })}
              type="number"
              color="primary"
              value={
                basin.currentaverageweights
                  ? intl.formatNumber(basin.currentaverageweights[0] || 0, {
                      style: 'decimal',
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    })
                  : '0'
              }
              inputProps={{
                style: {
                  ...theme.typography.h5,
                  color: theme.palette.primary.main,
                },
              }}
              onChange={(event) => {
                updateBasinData(
                  'newaverageweight',
                  event.target.value.replace(thousandSeparator, '')
                    .replace(',', '.').replace(/\s/g, ''),
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0} direction="row">
          <Grid item xs={6}>
            <Typography component="span" variant="caption" color="textPrimary">
              {`${intl.formatMessage({ id: 'FishCount' })} `}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FishAmountPopover
              component="span"
              variant="h5"
              suffix={intl.formatMessage({
                id: 'Pieces',
              })}
              color="primary"
              amount={intl.formatNumber(basin.currentamount || 0)}
              inputProps={{
                style: {
                  ...theme.typography.h5,
                  color: theme.palette.primary.main,
                },
              }}
              onChange={updateFishAmount}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0} direction="row">
          <Grid item xs={6}>
            <Typography component="span" variant="caption" color="textPrimary">
              {`${intl.formatMessage({ id: 'CumulativeFeedAmount' })} `}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CumulativeFeedAmountPopOver
              component="span"
              variant="h5"
              suffix={intl.formatMessage({ id: "UnitKg" })}
              color="primary"
              amount={intl.formatNumber(
                (basin.laststatus?.cumulativefeed_user || 0) / 1000,
                {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }
              )}
              inputProps={{
                style: {
                  ...theme.typography.h5,
                  color: theme.palette.primary.main,
                },
              }}
              onChange={updateFishAmount}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0} direction="row">
          <Grid item xs={6}>
            <Typography component="span" variant="caption" color="textPrimary">
              {`${intl.formatMessage({ id: 'SiloFeedEstimate' })} `}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {siloFeedEstimate !== 0 ? (
              <Typography variant="h5" component="span" color="primary">
                {
                  `${intl.formatNumber(
                  Number(siloFeedEstimate),
                  {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })} ${intl.formatMessage({ id: 'UnitKg' })}`
                }
              </Typography>
            ) : (
              <Typography variant="h5" component="span" color="primary">-</Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={0} direction="row">
          <Grid item xs={6}>
            <Typography component="span" variant="caption" color="textPrimary">
              {`${intl.formatMessage({ id: 'SiloProvisions' })} `}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5" component="span" color="primary">
              {
                `${intl.formatNumber(
                  Number(provisionDays),
                  {
                    style: 'decimal',
                    maximumFractionDigits: 1,
                    minimumFractionDigits: 1,
                  })} ${intl.formatMessage({ id: 'DayAbbreviation' })}`
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
