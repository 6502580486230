import { AxiosError } from 'axios';

const errorHandler = (error: AxiosError<any>) => {
  if (
    error.response?.status === 403 &&
    error.response?.data?.code === 'authentication_failed'
  ) {
    window.localStorage.removeItem('auth_token'); // remove invalid auth token
    window.location.href = '/login'; // redirect to login page
  }
  if (error.message?.includes('timeout')) {
    console.log('login timeout');
    // TODO: add error message to user (e.g.TOAST)
  }
  throw error;
};

export default errorHandler;
